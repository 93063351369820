@import "styles/mixins/eases";
@import "styles/variables";
@import "styles/mixins";
@import "styles/fonts";
@import "components/CanvasRenderer";
@import "components/Menu";
@import "components/CanvasContent";
@import "components/AnchorButton";
@import "components/RecordingUI";
@import "components/NotificationsUI";
@import "components/TitleContainer";
@import "components/ResultUI";
@import "components/InfoDialog";
@import "components/SongUI";
@import "components/Emotions";
@import "components/PagesWrapper";
@import "components/About";
@import "components/MySongs";

.webbyWinner {
    display: block;
    position: fixed;
    bottom: 15px;
    right: 30px;

    img {
        width: 70px;
        height: 70px;
    }
}

html,
body {
    font-size: 22px;
    font-family: $FONT;
    width: 100%;
    height: 100vh;
    vertical-align: baseline;
    margin: 0;
}
#SVGBlobs {
    display: none;
}
.statsjs {
    top: auto !important;
    left: auto !important;
    right: 0 !important;
    bottom: 0 !important;
}
body {
    width: 100%;
    height: 100%;
    background-color: $COLOR_BG;
    color: $COLOR_BLACK;
}

canvas {
    outline: none;
}

text {
    font-family: $FONT;
}


* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    font-family: $FONT;
}

a,
a:visited,
a:active,
a:hover {
    text-decoration: none;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
}
.tp-dfwv {
    display: none;
    @include bp(tp) {
        display: block;
    }
    right: auto;
    left: 88px;
}
body.preload * {
     -webkit-transition: none !important;
     -moz-transition: none !important;
     -ms-transition: none !important;
     -o-transition: none !important;
 }

#disableLayer {
    background: #FFF;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
}
audio {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100000;
}