.ResultUI {
    position: fixed;
    display: grid;
    grid-template-columns: max-content max-content;
    justify-content: center;
    z-index: 1;
    pointer-events: none !important;
    left: 32px;
    right: 32px;
    bottom: 16px + 40px + 10px;
    max-width: 460px;
    margin: 0 auto;
    @include bp(tp) {
        left: 32 + 80px;
        bottom: 40px + 40px + 10px;
    }
    @include bp(result) {
        display: flex;
        flex-flow: column;
        justify-content: center;
        flex-wrap: wrap;
        height: 300px;
        margin: 0;
        left: calc(#{$MENU_WIDTH} + 16px);
        top: calc(50% - 300px * 0.5);
    }
    @include bp(tl) {
        display: flex;
        flex-flow: column;
        justify-content: center;
        flex-wrap: wrap;
        height: 300px;
        margin: 0;
        left: calc(#{$MENU_WIDTH} + 40px);
        top: calc(50% - 300px * 0.5);
    }
    @include bp(ds) {
        left: calc(#{$MENU_WIDTH} + 80px);
    }
    &.results-1 {
        .ResultUI-item {
            @include bp(tl) {
                position: relative;
                top: 63px;
            }

            &:nth-child(1) {
                margin-bottom: 0;
            }
        }
    }

    &.results-2 {
        .ResultUI-item {
            @include bp(tl) {
                position: relative;
                top: 63px;
            }

            &:nth-child(2) {
                margin-bottom: 0;
            }
        }
    }

    &.results-3 {
        .ResultUI-item {
            @include bp(tl) {
                position: relative;
                top: 21px;
            }

            &:nth-child(3) {
                margin-bottom: 0;
            }
        }
    }

    .ResultUI-item {
        display: flex;
        flex-flow: row;
        align-items: center;
        margin-bottom: 10px;
        padding: 0 10px;
        @include bp(tl) {
            margin-bottom: 30px;
            &:last-child {
                margin-bottom: 0;
            }
        }

        .ResultUI-value {
            width: 38px;
            height: 21px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            //background: $COLOR_BG;
            @include bp(tph) {
                width: 60px;
                height: 32px;
            }

            @include bp(ds) {
                width: 80px;
                height: 42px;
            }

            span {
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 12px;
                color: #FFFFFF;
                text-align: center;
                display: block;
                position: relative;
                z-index: 1;
                @include bp(tph) {
                    font-size: 18px;
                    line-height: 18px;
                }
                @include bp(ds) {
                    font-size: 24px;
                    line-height: 14px;
                }
            }

            svg {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 0;
                width: 38px;
                height: 21px;
                //mix-blend-mode: multiply;
                @include bp(tph) {
                    width: 60px;
                    height: 32px;
                }
                @include bp(ds) {
                    width: 80px;
                    height: 42px;
                }
            }
        }

        .ResultUI-label {
            margin-left: 5px;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            color: #0D2740;
            @include bp(tph) {
                margin-left: 10px;
                font-size: 18px;
                line-height: 18px;
            }
            @include bp(ds) {
                margin-left: 10px;
                font-size: 24px;
                line-height: 14px;
            }
        }

        transform-style: flat;
        transform: perspective(800px) rotateY(35deg) scale(0.6) rotateX(25deg);
        filter: blur(2px);
        opacity: 0;
        transition: 1.2s transform ease, 1.2s filter ease, 1s opacity ease, background-color 0.3s ease;
        transition-delay: 2.1 - 0.5s;

        &:nth-child(2) {
            transition-delay: 2.2 - 0.5s;
        }

        &:nth-child(3) {
            transition-delay: 2.3 - 0.5s;
        }

        &:nth-child(4) {
            transition-delay: 2.4 - 0.5s;
        }


        &.visible {
            pointer-events: auto !important;
            transform: perspective(800px) rotateY(0deg) translateY(0px) rotateX(0deg) scale(1);
            filter: blur(0);
            opacity: 1;
        }
        &.hidden {
            transition: 1s ease transform, 1s ease filter, 0.8s opacity ease, background-color 0.3s ease;
            transition-delay: 0s;
            transform: perspective(800px) rotateY(25deg) scale(0.6) rotateX(-25deg);
            filter: blur(2px);
            opacity: 0;
            pointer-events: none !important;
        }
    }
}