.NotificationsUI {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    @include bp(tp) {
        left: 0;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #FEF7EF;
        z-index: 0;
        opacity: 0;
        transform-style: flat;
        //transform: translateZ(-1000px);
        transition: 0.6s ease opacity;
    }

    &.show {
        pointer-events: auto;

        &:before {
            opacity: 0.8;
        }

    }

    .NotificationsUI-inner {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transform-style: flat;
        perspective-origin: center center;
        perspective: 800px;
        display: flex;
        flex-direction: column;
    }

    .NotificationUI-dialog {
        z-index: 10000; /* [1] */
        grid-area: 1/-1;
        position: absolute;
        display: flex;
        flex-direction: column;
        margin: auto; /* [2] */
        text-align: center;
        align-items: center;
        background: #FEF7EF;
        border: 1px solid rgba(#C9B39D, 0.9);
        box-sizing: border-box;
        border-radius: 25px;
        padding: 20px 20px 20px 20px;
        background: rgba($COLOR_BG, 0.9);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        max-width: calc(100vw - 32px);
        transition: 1.2s transform ease, 1.2s filter ease, 1s opacity ease;
        transform-style: flat;
        transform: perspective(800px) rotateY(35deg) scale(0.6) rotateX(25deg) translateY(-50%);
        filter: blur(2px);
        opacity: 0;
        pointer-events: none !important;
        left: 0;
        right: 0;
        top: 50%;
        &.bottom {
            transform: perspective(800px) rotateY(35deg) scale(0.6) rotateX(25deg);
            top: auto;
        }
        @media (min-width: 490px) {
            max-width: 450px;
        }
        @include bp(tph) {
            padding: 20px 35px 20px 35px;
        }

        &.noVocalBurst {
            margin-bottom: 0;
            bottom: 16px;

            @include bp(tph) {
                bottom: 60px;
            }
        }

        &.share {
            p {
                text-transform: uppercase;
                font-weight: 500;
                font-size: 16px;
            }
            @include bp(tp) {
                min-width: 450px;
            }
            .share-links {
                display: flex;
                flex-flow: row;
                margin-top: 20px;
                a {
                    margin-right: 29px;
                    width: 49px;
                    height: 49px;
                    border: 1px solid #D0BAA3;
                    border-radius: 50%;
                    text-indent: -99999px;
                    overflow: hidden;
                    display: block;
                    position: relative;
                    &:after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0px;
                        width: 100%;
                        height: 100%;
                        display: block;
                        background-repeat: no-repeat;
                        background-position: center center;
                        will-change: transform;
                        transition: transform 0.3s ease;
                    }
                    &:nth-child(1):after {
                        background-image: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25 12.5C25 5.596 19.404 0 12.5 0S0 5.596 0 12.5c0 6.239 4.571 11.41 10.547 12.348v-8.735H7.373V12.5h3.174V9.746c0-3.133 1.866-4.863 4.721-4.863 1.367 0 2.798.244 2.798.244v3.076H16.49c-1.552 0-2.037.964-2.037 1.953V12.5h3.467l-.554 3.613h-2.913v8.735C20.43 23.91 25 18.74 25 12.5Z' fill='%23C9B39D'/%3E%3C/svg%3E");
                    }
                    &:nth-child(2):after {
                        background-image: url("data:image/svg+xml,%3Csvg width='24' height='21' viewBox='0 0 24 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.55 20.657c9.055 0 14.008-7.816 14.008-14.592 0-.22-.004-.445-.014-.664A10.24 10.24 0 0 0 24 2.745a9.407 9.407 0 0 1-2.826.806A5.126 5.126 0 0 0 23.339.715a9.66 9.66 0 0 1-3.126 1.244A4.873 4.873 0 0 0 17.42.411a4.76 4.76 0 0 0-3.122.54 5.058 5.058 0 0 0-2.164 2.405 5.333 5.333 0 0 0-.315 3.28A13.55 13.55 0 0 1 6.2 5.082 14.108 14.108 0 0 1 1.673 1.28a5.32 5.32 0 0 0-.538 3.736 5.141 5.141 0 0 0 2.061 3.108 4.78 4.78 0 0 1-2.23-.64v.064a5.26 5.26 0 0 0 1.112 3.25 4.891 4.891 0 0 0 2.838 1.78 4.704 4.704 0 0 1-2.221.087 5.138 5.138 0 0 0 1.75 2.55c.825.638 1.82.993 2.847 1.015a9.626 9.626 0 0 1-6.115 2.197c-.394 0-.787-.026-1.177-.075a13.565 13.565 0 0 0 7.55 2.303Z' fill='%23C9B39D'/%3E%3C/svg%3E");
                    }
                    &:nth-child(3):after {
                        background-image: url("data:image/svg+xml,%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22.223 0H1.772C.792 0 0 .806 0 1.802v21.391C0 24.19.792 25 1.772 25h20.451c.98 0 1.777-.81 1.777-1.802V1.802C24 .806 23.203 0 22.223 0ZM7.12 21.304H3.558V9.37H7.12v11.934ZM5.34 7.744c-1.145 0-2.068-.962-2.068-2.148 0-1.187.923-2.149 2.067-2.149 1.14 0 2.063.962 2.063 2.149 0 1.181-.924 2.148-2.063 2.148Zm15.112 13.56h-3.558v-5.801c0-1.382-.024-3.164-1.852-3.164-1.851 0-2.133 1.509-2.133 3.066v5.899H9.356V9.37h3.413v1.631h.047c.473-.938 1.636-1.929 3.365-1.929 3.605 0 4.27 2.471 4.27 5.684v6.548Z' fill='%23C9B39D'/%3E%3C/svg%3E");
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                    &:hover {
                        &:after {
                            transform: scale(1.3);
                        }
                    }
                }
            }
        }

        &.visible {
            pointer-events: auto !important;
            transform: perspective(800px) rotateY(0deg) translateY(-50%) rotateX(0deg) scale(1);
            filter: blur(0);
            opacity: 1;
            &.bottom {
                transform: perspective(800px) rotateY(0deg) translateY(0) rotateX(0deg) scale(1);
            }
        }

        > svg {
            width: 32px;
            height: 40px;
            margin-bottom: 14px;
        }

        p {
            font-style: normal;
            font-weight: normal;
            text-align: center;
            font-size: 12px;
            line-height: 15px;
            @include bp(tph) {
                font-size: 18px;
                line-height: 23px;
            }

            svg {
                width: 16px;
                height: 18px;
                margin-left: 2px;
                margin-right: 2px;
            }
        }

        a {
            text-decoration: underline;
            cursor: pointer;
        }

        button {
            cursor: pointer;
            appearance: none;
            -webkit-appearance: none;
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: #000000;
            font-family: $FONT;
            justify-content: center;
            margin-top: 20px;
            background: #FEF7EF;
            border: 1px solid #D0BAA3;
            box-sizing: border-box;
            //backdrop-filter: blur(40px);
            border-radius: 40px;
            height: 30px;
            width: 100px;
            transition: background-color 0.3s;
            @media (hover: hover) {
                &:hover {
                    background: rgba(#D0BAA3, 0.7);
                }
            }
        }
    }
}

html.is-safari {
    .hide-safari {
        display: none;
    }
}