.RecordingUI {
    width: 200px * 0.6;
    height: 240px * 0.6;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: calc(50% - 120px * 0.6);
    left: calc(50% - 100px * 0.6);
    pointer-events: none;

    @include bp(tph) {
        width: 200px;
        height: 240px;
        top: calc(50% - 120px);
        left: calc(50% - 100px);
    }

    svg {
        width: 49px;
        height: 36px;
        transform: scale(0.5);
        will-change: transform;
        opacity: 0;
        transition: transform 0.5s ease, opacity 1.5s ease-in-out;
        @include bp(tph) {
            width: 49px;
            height: 60px;
        }

        path:nth-child(2) {
            fill: transparent;
            transition: all 0.2s ease;
        }

        /*        g path {
                    //fill: #C9B39D;
                    transform: translateY(60%);
                    transition: all 0.2s ease;
                }*/
    }

    .RecordingUI-label {
        position: absolute;
        bottom: -40px;
        font-style: normal;
        text-align: center;
        color: #C9B39D;
        transform: translateY(10px);
        opacity: 0;
        will-change: transform;
        transition: transform 1s ease, opacity 0.75s ease-in-out;
        transition-delay: 1.5s;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        overflow: hidden;
        white-space: nowrap;

        .touch {
            display: none;
        }

        @include bp(tph) {
            bottom: -50px;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
        }
    }

    &.visible {
        .RecordingUI-label {
            opacity: 1;
            transform: translateY(0px);
        }

        svg {
            opacity: 0.8;
            transform: scale(0.8);
        }
    }

    &.ready {
        svg {
            transition: transform 0.5s ease, opacity .5s ease-in-out;
        }

        .RecordingUI-label {
            transition: transform 0.3s ease, opacity 0.3s ease-in-out;
            transition-delay: 0s;
        }
    }

    &.reset {
        display: none;
    }

    &.hidden {
        .RecordingUI-label {
            transition: transform 1s ease, opacity 0.75s ease-in-out;
            transition-delay: 0s;
            opacity: 0;
            transform: translateY(0px);
        }

        svg {
            opacity: 0;
            transform: scale(0.5);
            transition: transform 0.5s ease, opacity 1.5s ease-in-out;
        }
    }

    &.recording {
        .RecordingUI-label {
            transform: translateY(10px);
            opacity: 0;
        }

        svg {
            opacity: 1;
            transform: scale(1);

            /*            g path {
                            transition: all 30s linear;
                            //fill: #C9B39D;
                            transform: translateY(5%);
                        }*/
        }
    }
}

html.is-touch {
    .RecordingUI-label {
        .desktop {
            display: none;
        }

        .touch {
            display: block;
        }
    }
}