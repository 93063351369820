$FONT: 'Faktum', sans-serif;
$FONT_WIDE: 'Faktum-WideBold', sans-serif;

$COLOR_WHITE: #ffffff;
$COLOR_BLACK: #000000;
$COLOR_BG: #FEF7EF;

// Breakpoints
$BREAKPOINT_DESKTOP_MAX: 2550px;
$BREAKPOINT_DESKTOP_BIG: 1850px;
$BREAKPOINT_DESKTOP_SMALL: 1440px;
$BREAKPOINT_DESKTOP_MARGIN: 1200px;
$BREAKPOINT_TABLET_LANDSCAPE: 1024px;
$BREAKPOINT_TABLET_PORTRAIT: 768px;
$BREAKPOINT_MOBILE_BIG: 640px;
$BREAKPOINT_MOBILE_SMALL: 375px;

$MENU_WIDTH: 80px;
$MENU_WIDTH_EXPANDED: 312px;
$MENU_HEIGHT_MOBILE: 50px;