.TitleContainer-wrapper {
    display: grid;
    pointer-events: none;
    perspective: 800px;
    perspective-origin: 50% 50%;
    width: 100%;
    //overflow: hidden;
    @include bp(tp) {
        width: auto;
    }
}

html.is-safari {
    .TitleContainer {
        z-index: 1;
    }
}
.TitleContainer {
    position: relative;
    grid-area: 1/-1;
    max-width: 100%;
    text-align: center;
    opacity: 0;
    padding-left: 20px;
    padding-right: 20px;
    transition: 1.2s transform ease, 1.2s filter ease, 1s opacity ease;
    transform: perspective(800px) translateZ(-500px) translateX(-50px) rotateY(35deg) rotateX(25deg);
    //will-change: transform;
    filter: blur(8px);
    display: flex;
    flex-flow: column;
    @include bp(tph) {
        max-width: 705px;
    }

    h1 {
        margin-bottom: 16px;
        @include bp(tph) {
            margin-bottom: 20px;
        }
    }

    p {
        max-width: 100%;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        @include bp(tph) {
            font-weight: 500;
            font-size: 18px;
            line-height: 23px;

        }
        /*        transition: 1.2s ease all, 1s opacity ease;
                transform: translateZ(-800px) translateX(0px)
                rotateY(35deg)
                rotateX(25deg);
                //will-change: transform;
                filter: blur(8px);
                opacity: 0;*/

        &.TitleContainer-helper {
            margin-top: 16px;
            opacity: 0.5;

            &--tap {
                display: block;
                @extend .TitleContainer-helper;
                @media (hover: hover) {
                    display: none;
                }
            }

            &--hover {
                display: none;
                @extend .TitleContainer-helper;
                @media (hover: hover) {
                    display: block;
                }
            }
        }

        a {
            pointer-events: auto;
        }

        br {
            @include bp(tph) {
                display: none;
            }
        }
    }

    span {
        &.TitleContainer-helper {
            &--tap {
                display: block;
                @media (hover: hover) {
                    display: none;
                }
            }

            &--hover {
                display: none;
                @media (hover: hover) {
                    display: block;
                }
            }
        }
    }

    /*    h1 {
            transform-style: preserve-3d;
            perspective-origin: 50% 50%;
            perspective: 1600px;
            div {
    !*            font-kerning: none;
                -webkit-text-rendering: optimizeSpeed;
                text-rendering: optimizeSpeed;*!
                transition: 1.2s ease all, 1s opacity ease;
                transform: translateZ(-800px) translateX(0px)
                rotateY(35deg)
                rotateX(25deg);
                //will-change: transform;
                filter: blur(8px);
                opacity: 0;
            }
        }*/

    &.visible {
        //opacity: 1;
        transition: 1.2s transform ease, 1.2s filter ease, 1s opacity ease;
        transform: translateZ(0px) translateX(0px) rotateY(0deg) rotateX(0deg);
        filter: blur(0px);
        opacity: 1;
        /*        p {
                    transition: 1.5s ease all, 1.2s opacity ease;
                    transform: translateZ(0px) translateX(0px)
                    rotateY(0deg)
                    rotateX(0deg);
                    filter: blur(0);
                    opacity: 1;
                }
                h1 {
                    div {
                        transition: 1.2s ease all, 1s opacity ease;
                        transform: translateZ(0px) translateX(0px)
                        rotateY(0deg)
                        rotateX(0deg);
                        filter: blur(0);
                        opacity: 1;
                    }
                }*/
    }

    &.hidden {
        opacity: 0;
        //transition: opacity 0s 1.2s;
        transition: 0.6s transform ease, 0.6s filter ease, 0.5s opacity ease;
        transform: translateZ(250px) translateX(0px) scale(0.9) /*        rotateY(-25deg)
        rotateX(-25deg)*/
    ;
        filter: blur(4px);
        /*        h1 {
                    div {
                        transition: 1.2s ease all, 1s opacity ease;
                        transform: translateZ(100px) translateX(0px)
                        rotateY(25deg)
                        rotateX(25deg);
                        filter: blur(4);
                        opacity: 0;
                    }
                }*/
    }
}

.PagesWrapper {
    .TitleContainer {
        @include bp(tph) {
            max-width: 860px;
        }
    }
}