.CanvasRenderer {
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    canvas {
        height: 100%;
        width: 100%;
    }
}
