.InfoDialog-dialog {
    position: absolute;
    display: flex;
    flex-direction: column;
    margin: auto; /* [2] */
    text-align: center;
    align-items: center;
    background: #FEF7EF;
    border: 1px solid rgba(#C9B39D, 0.9);
    box-sizing: border-box;
    border-radius: 20px;
    padding: 20px;
    background: rgba($COLOR_BG, 0.9);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    transition: 1.2s transform ease, 1.2s filter ease, 1s opacity ease;
    transform-style: flat;
    transform: perspective(800px) rotateY(35deg) scale(0.6) rotateX(25deg);
    filter: blur(2px);
    opacity: 0;
    pointer-events: none !important;
    left: 16px;
    bottom: 16px;
    right: 16px;
    cursor: pointer;
    z-index: 2;
    @media (min-width: 400px) {
        right: auto;
        max-width: 236px;
    }
    @include bp(tph) {
        padding: 35px;
        left: 20px;
        bottom: 30px;
    }
    @include bp(tl) {
        left: 40px;
    }
    @include bp(ds) {
        left: 80px;
        bottom: 60px;
    }

    &.visible {
        pointer-events: auto !important;
        transform: perspective(800px) rotateY(0deg) translateY(0px) rotateX(0deg) scale(1);
        filter: blur(0);
        opacity: 1;
    }

    p {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
    }
}

.InfoDialog-button {
    position: absolute;
    left: 16px;
    bottom: 16px;
    width: 40px;
    height: 40px;
    border: 1px solid #D0BAA3;
    box-sizing: border-box;
    background: rgba(#FEF7EF, 0.7);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 35px;
    flex-grow: 0;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    pointer-events: none !important;
    transition: 1.2s transform ease, 1.2s filter ease, 1s opacity ease, background-color 0.3s;
    transform-style: flat;
    transform: perspective(800px) rotateY(35deg) scale(0.6) rotateX(25deg);
    filter: blur(2px);
    opacity: 0;
    display: none;
    @include bp(tph) {
        left: 20px;
        display: flex;
        width: 40px;
        height: 40px;
        bottom: 30px;
    }
    @include bp(tl) {
        left: 40px;
    }
    @include bp(ds) {
        left: 80px;
        bottom: 60px;
    }

    svg {
        path {
            transition: 0.3s;
        }
    }

    &.visible {
        pointer-events: auto !important;
        transform: perspective(800px) rotateY(0deg) translateY(0px) rotateX(0deg) scale(1);
        filter: blur(0);
        opacity: 1;
    }

    @media (hover: hover) {
        &:hover {
            background: rgba(245, 232, 219, 0.7);
            //background: rgba(#D0BAA3, 0.7);
            svg path {
                //fill: #FEF7EF;
            }
        }
    }
}