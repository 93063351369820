#about {
    flex-flow: column;
    align-items: center;

    .page-content {
        margin-top: 32px;
        @include bp(tp) {
            margin-top: 116px;
        }
    }

    .AboutRow {
        display: flex;
        width: 100%;
        flex-flow: column;
        border-top: 1px solid #C9B39D;
        margin-top: 32px;
        &:first-child {
            margin-top: 0;
        }
        @include bp(tp) {
            border-top: none;
            flex-flow: row;
            justify-content: space-between;
            margin-top: 140px;
            &:first-child {
                margin-top: 0;
            }
        }

        h2 {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            margin-top: 32px;
            @include bp(tp) {
                margin-top: 0px;
            }
            @include bp(tph) {
                font-size: 32px;
                line-height: 40px;
            }
        }

        p {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 15px;
            margin-top: 20px;
            @include bp(tp) {
                margin-top: 0px;
                width: 61%;
                font-weight: 500;
            }
            @include bp(tph) {
                font-size: 18px;
                line-height: 23px;
            }
        }
    }
}