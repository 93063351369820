.CanvasContent {
    position: fixed;
    height: 100%;
    width: 100%;
    padding-top: $MENU_HEIGHT_MOBILE + 35px;
    padding-bottom: 35px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    pointer-events: none;
    overflow: hidden;
    @include bp(tp) {
        width: calc(100% - #{$MENU_WIDTH});
        margin-left: $MENU_WIDTH;
        padding-top: 35px;
    }
    @include bp(tph) {
        padding-top: 66px;
        padding-bottom: 60px;
    }
}
html.is-safari {
    .CanvasContent {
        z-index: 1;
    }
}
#motionPath {
    position: fixed;
    top: 0;
    left: 0;
    //width: calc(100% - #{$MENU_WIDTH});
    width: 100%;
    height: 100%;
    pointer-events: none;
    visibility: hidden;
    &.visible {
        visibility: visible;
    }
    #pathVisiblePATH {
        stroke: #0033ff;
    }
    #pathLanding2PATH {
        stroke: #ff8000;

    }
    rect {
        stroke: #000000;
    }
/*    path {
        stroke-width: 3px;
        stroke: #88ce02;
        fill: none;
    }*/
}
.EmotionHoverLabel {
    position: absolute;
    top: 0;
    left: 0px;
    width: 200px;
    text-align: center;
    will-change: transform;
    font-style: normal;
    font-weight: 500;
    line-height: 1em;
    font-size: 16px;
    pointer-events: none;
    @include bp(tp) {
        left: -80px;
    }
    @include bp(tph) {
        font-size: 24px;
    }
    color: #000000;


}