
.Menu-hamburger {
    width: $MENU_HEIGHT_MOBILE;
    height: $MENU_HEIGHT_MOBILE;
    position: fixed;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    z-index: 4;
    top: 0;
    right: 0;
    @include bp(tp) {
        left: 0;
        right: auto;
        width: $MENU_WIDTH;
        height: $MENU_WIDTH;
    }

    button {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        appearance: none;
        opacity: 0;
        cursor: pointer;
    }

    .Menu-hamburger-line {
        width: 18px;
        height: 2px;
        border-radius: 1px;
        margin-bottom: 4px;
        background: #000;
        transition: transform 0.3s ease, opacity 0.15s ease;
        transform-origin: 3% 50%;
        @include bp(tp) {
            transform-origin: 1px 50%;
            width: 30px;
            height: 3px;
            border-radius: 1.5px;
            margin-bottom: 7px;
        }
    }

    div:nth-child(3) {
        margin-bottom: 0 !important;
    }
}

.Menu-logo {
    position: fixed;
    display: block;
    z-index: 4;
    transition: 0.4s transform $ease-in-out-quad;
    bottom: 25px;
    transform: translateY(100px);
    left: calc(50% - 16px);
    opacity: 0;
    @include bp(tp) {
        transform: translateX(0px);
        opacity: 1;
        left: 40px - 16px;
    }

}

nav {

    display: flex;
    flex-flow: column;
    align-items: center;
    text-align: center;

    a {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 25px;
        color: #C9B39D;
        opacity: 0;
        transition: color 0.3s, opacity 0.2s $ease-in-out-sine, transform 0.6s 0s ease;
        transform: translateY(-200px);
        @include bp(tp) {
            margin-bottom: 10px;
            transition: color 0.3s, opacity 0.4s $ease-in-out-sine, transform 0.6s 0s ease;
            transform: translateX(-200px);
        }
        @include bp(tphLow) {
            font-size: 20px;
            line-height: 26px;
        }
        @include bp(tph) {
            margin-bottom: 25px;
        }

        &.externalLink {
            font-size: 13px;
            text-decoration: underline;
        }
        &.active {
            color: #000000;
        }

        &:hover {
            color: #424242;

        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.Menu {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba($COLOR_BG, 0.9);
    display: flex;
    flex-flow: row-reverse;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-bottom: 1px solid rgba(#C9B39D, 0.9);
    z-index: 3;
    transform: translateY(calc(-100% + #{$MENU_HEIGHT_MOBILE}));
    transition: 0.4s transform $ease-in-out-quad;
    @include bp(tp) {
        transform: translateX(calc(-100% + 80px));
        border-right: 1px solid rgba(#C9B39D, 0.9);
        border-bottom: none;
        width: $MENU_WIDTH_EXPANDED;
        height: 100%;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
}

.Menu-logoType {
    transform: rotateZ(90deg);
    width: 13px;
    margin-left: 48px;
    transition: opacity 0.4s $ease-in-out-sine;
    margin-top: auto;
    position: fixed;
    left: 0;
    top: -36px;
    z-index: 4;
    @include bp(tp) {
        width: auto;
        margin-left: 0;
        transition: opacity 0.4s $ease-in-out-sine, transform 0.3s $ease-in-out-quad;
        transform: translateX(0);
        left: 31px;
        //right: 40px - 8px;
        top: calc(50% - 59px);
    }
}

html.menu-open {
    .Menu-logo {
        transition: .8s .2s transform $ease-in-out-sine, opacity 0.5s 0.3s $ease-in-out-sine;
        transform: translateY(0) rotateZ(206deg);
        opacity: 1;
        @include bp(tp) {
            transition: .8s transform $ease-in-out-quad;
            transform: translateX(#{$MENU_WIDTH_EXPANDED * 0.5 - 40px}) rotateZ(206deg);
        }
    }

    .Menu-hamburger-line {
        &:nth-child(1) {
            transform: rotateZ(45deg);
        }

        &:nth-child(2) {
            opacity: 0;
            transform: translateX(50%);
        }

        &:nth-child(3) {
            transform: rotateZ(-45deg);
        }
    }

    nav a {
        &:nth-child(1) {
            transition: color 0.3s, opacity 0.4s 0.2s $ease-in-out-sine, transform 0.8s 0s $ease-out-sine;
        }

        &:nth-child(2) {
            transition: color 0.3s, opacity 0.4s 0.2s $ease-in-out-sine, transform 0.75s 0.05s $ease-out-sine;
        }

        &:nth-child(3) {
            transition: color 0.3s, opacity 0.4s 0.2s $ease-in-out-sine, transform 0.7s 0.1s $ease-out-sine;
        }

        &:nth-child(4) {
            transition: color 0.3s, opacity 0.4s 0.2s $ease-in-out-sine, transform 0.65s 0.15s $ease-out-sine;
        }


        &:nth-child(5) {
            transition: color 0.3s, opacity 0.4s 0.2s $ease-in-out-sine, transform 0.6s 0.2s $ease-out-sine;
        }

        transform: translateY(0px);
        @include bp(tp) {
            transform: translateX(0px);
        }
        opacity: 1;
    }

    .Menu {
        transform: translateY(0);
        @include bp(tp) {
            transform: translateX(0);
        }
    }

    @include bp(tp) {
        .Menu-logoType {
            transform: translateX(-60px);
            opacity: 0;
        }
    }
}