@import "mixins/poly_fluid_sizing";
@import "mixins/ui";

$media: screen;
$feature: min-width;
@mixin bp($breakpoint) {
    @if $breakpoint == ms {
        @media #{$media} and ($feature: $BREAKPOINT_MOBILE_SMALL) {
            @content;
        }
    } @else if $breakpoint == tp {
        @media #{$media} and ($feature: $BREAKPOINT_TABLET_PORTRAIT) {
            @content;
        }
    } @else if $breakpoint == tph {
        @media #{$media} and ($feature: $BREAKPOINT_TABLET_PORTRAIT) and (min-height: 500px) {
            @content;
        }
    } @else if $breakpoint == tphLow {
        @media #{$media} and ($feature: $BREAKPOINT_TABLET_PORTRAIT) and (max-height: 499px) {
            @content;
        }
    } @else if $breakpoint == result {
        @media #{$media} and ($feature: $BREAKPOINT_TABLET_PORTRAIT) and (max-height: 900px) and (max-width: $BREAKPOINT_TABLET_LANDSCAPE - 1px) {
            @content;
        }
    } @else if $breakpoint == tl {
        @media #{$media} and ($feature: $BREAKPOINT_TABLET_LANDSCAPE) {
            @content;
        }
    } @else if $breakpoint == ds {
        @media #{$media} and ($feature: $BREAKPOINT_DESKTOP_SMALL) {
            @content;
        }
    } @else if $breakpoint == db {
        @media #{$media} and ($feature: $BREAKPOINT_DESKTOP_BIG) {
            @content;
        }
    }
}