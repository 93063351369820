#emotions {
    width: 100%;
    flex-flow: column;
    align-items: center;
    position: fixed;


    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 32px;
        padding-top: 52px;
        border-top: 1px solid #C9B39D;
        @include bp(tp) {
            border-top: none;
            padding-top: 0;
            margin-top: 90px;
        }
        @include bp(tphLow) {
            margin-top: 30px;
        }

        li {
            list-style: none;
            margin: 0;
            padding: 0;
            width: 50%;
            @include bp(tp) {
                width: 20%;
            }
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 23px;
            text-align: center;
            color: #0D2740;
            display: flex;
            flex-flow: column;
            align-items: center;
            cursor: pointer;
            @include bp(tphLow) {
                font-size: 16px;
                line-height: 21px;
            }

            div {
                width: 80%;
                margin-top: 15%;
                margin-bottom: 15%;
                @include aspect-ratio(1, 1);
            }

        }
    }
}