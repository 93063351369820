.SongUI {
    display: flex;
    flex-flow: column;
    position: absolute;
    top: 0;
    bottom: 0;
    width: calc(100% - 60px);
    max-width: 270px;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    pointer-events: none;
    visibility: hidden;

    @media (max-height: 767px) {
        max-width: 50vw;
    }
    @include bp(tl) {
        max-width: 18vw;
    }
    &.no-preview {
        .PlayButton {
            display: none !important;
        }
    }
}

.SongUI-albumArt {
    display: grid;
    width: 100%;
    @include bp(result) {
        max-width: 180px;
    }
    @include bp(tph) {
        width: 100%;
    }
    @include aspect-ratio(1, 1);
    transition: 1.2s transform ease, 1.2s filter ease, 1s opacity ease;
    transform-style: flat;
    transform: perspective(800px) rotateY(35deg) scale(0.6) rotateX(25deg);
    filter: blur(2px);
    opacity: 0;
    pointer-events: none !important;
    background: rgba(#FEF7EF, 0.7);
    //backdrop-filter: blur(4px);//Maybe remove for better performance
    //-webkit-backdrop-filter: blur(4px);//Maybe remove for better performance
    border-radius: 25px;
    padding: 5px;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        left: 5px;
        top: 5px;
        bottom: 5px;
        right: 5px;
        background: #f9e2cc;
        border-radius: 20px;
        opacity: 0.5;
        z-index: -1;
    }

    &.visible {
        pointer-events: auto !important;
        transform: perspective(800px) rotateY(0deg) translateY(0px) rotateX(0deg) scale(1);
        filter: blur(0);
        opacity: 1;
    }

    img {
        position: absolute;
        top: 5px;
        left: 5px;
        width: calc(100% - 10px);
        height: calc(100% - 10px);
        border-radius: 20px;
        overflow: hidden;
        outline: transparent;
        appearance: none;
        opacity: 0;
        transition: opacity 0.2s;

        &.loaded {
            opacity: 1;
        }
    }
}

.PlayButton {
    position: absolute;
    width: 80px;
    height: 80px;
    /* Note: backdrop-filter has minimal browser support */
    flex-grow: 0;
    text-indent: -9999px;
    overflow: hidden;
    align-items: center;
    text-align: center;
    pointer-events: auto;
    cursor: pointer;
    left: calc(50% - 40px);
    top: calc(50% - 40px);
    box-sizing: border-box;
    background: rgba(#FEF7EF, 0.7);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    transition: background-color 0.3s, transform 0.3s ease;
    will-change: transform;
    border-radius: 40px;
    @include bp(tph) {
        width: 100px;
        height: 100px;
        left: calc(50% - 50px);
        top: calc(50% - 50px);
        border-radius: 50px;
    }

    button {
        position: absolute;
        cursor: pointer;
        inset: 0;
        opacity: 0;
        appearance: none;
        padding: 0;
        margin: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

    svg {
        width: 24px;
        height: 28px;
        position: absolute;
        left: calc(50% - 22px * 0.5);
        top: calc(50% - 28px * 0.5);
        @include bp(tph) {
            width: 24px;
            height: 28px;
            left: calc(50% - 22px * 0.5);
            top: calc(50% - 28px * 0.5);
        }
        transition: opacity 0.3s ease;

        &:nth-child(2) {
            width: 18px;
            height: 26px;
            left: calc(50% - 18px * 0.5);
            top: calc(50% - 26px * 0.5);
            opacity: 0;
        }
    }

    &.playing {
        transform: scale(0.9);

        svg {
            &:nth-child(1) {
                opacity: 0;
            }

            &:nth-child(2) {
                opacity: 1;
            }
        }
    }

    @media (hover: hover) {
        &:hover {
            background: rgba(245, 232, 219, 0.7);
            //background: rgba(#D0BAA3, 0.7);
            svg path {
                //fill: #FEF7EF;
            }
        }
    }

}

.SongUI-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    background: #FEF7EF;
    border: 1px solid rgba(#C9B39D, 0.9);
    box-sizing: border-box;
    border-radius: 25px;
    color: #000000;
    padding: 10px;
    background: rgba($COLOR_BG, 0.9);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    transition: 1.2s transform ease, 1.2s filter ease, 1s opacity ease;
    transform-style: flat;
    transform: perspective(800px) rotateY(35deg) scale(0.6) rotateX(25deg);
    filter: blur(2px);
    opacity: 0;
    pointer-events: none !important;
    position: absolute;
    top: 50%;
    margin-top: calc(50% + 20px);
    @media (max-height: 650px) and (min-height: 460px) and (min-width: 320px) and (max-width: 767px) {
        display: none;
    }

    @include bp(result) {
        margin-top: calc(50% + 10px);
        margin-bottom: -50px;
    }
    @include bp(tph) {
        padding: 20px;
    }
    @include bp(tphLow) {
        position: relative;
        top: 0;
        margin-top: 10px;
    }

    @media (hover: hover) {
        &:hover {
            background: rgba(245, 232, 219, 0.7);
        }
    }

    &.visible {
        pointer-events: auto !important;
        transform: perspective(800px) rotateY(0deg) translateY(0px) rotateX(0deg) scale(1);
        filter: blur(0);
        opacity: 1;
    }

    h5 {
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 21px;
        @include bp(tph) {
            font-size: 16px;
            line-height: 24px;
        }
    }

    h3 {
        font-style: normal;
        font-weight: 500;
        font-size: 19px;
        line-height: 21px;
        @include bp(tph) {
            font-size: 22px;
            line-height: 24px;
        }
        @include bp(result) {
            font-size: 16px;
            line-height: 19px;
        }
    }


    span {
        font-style: normal;
        font-weight: 600;
        color: #D0BAA3;
        font-size: 10px;
        line-height: 21px;
        @include bp(tph) {
            font-size: 13px;
            line-height: 24px;
        }
    }
}

.SongUI-buttons {
    position: absolute;
    bottom: 16px;
    display: flex;
    flex-flow: row;
    height: 40px;
    @include bp(tp) {
        bottom: 32px;
    }
    @include bp(result){
        bottom: auto;
        top: calc(50% - (40px * 3 + 20px) * 0.5);
        height: 40px * 3 + 20px;
        //height: auto;
        right: 16px;
        display: block;
    }
    @include bp(tl) {
        bottom: auto;
        top: calc(50% - (42px * 3 + 40px) * 0.5);
        height: 42px * 3 + 40px;
        //height: auto;
        right: 42px;
        display: block;
        //flex-flow: column;
    }
    @include bp(ds) {
        right: 82px;
    }
    pointer-events: none;

    .AnchorButton {
        width: 90px;
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }

        & + button.AnchorButton {
            transform: translateX(90px);
        }

        @media (min-width: 375px) {
            width: 105px;
            & + button.AnchorButton {
                transform: translateX(90px);
            }
        }
        @include bp(tp) {
            width: 160px;
            & + button.AnchorButton {
                transform: translateX(0px);
            }
        }
        @include bp(tphLow){
            width: 115px;
            margin-right: 0px;
        }
        @include bp(tl) {
            margin-right: 0px;
            & + button.AnchorButton {
                transform: translateX(0px);
            }
        }

        &.visible + button.AnchorButton {
            transform: translateX(0%);
        }
    }

    button {
        cursor: pointer;
        margin-bottom: 20px;
        @include bp(tphLow){
            margin-bottom: 10px;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}