.AnchorButton {
    background: rgba(#FEF7EF, 0.7);
    border: 1px solid #D0BAA3;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 20px;
    height: 40px;
    font-size: 12px;
    letter-spacing: 0.05em;
    font-style: normal;
    font-weight: 500;
    flex-grow: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
    transition: 1.2s ease transform, 1.2s ease filter, 1s opacity ease, background-color 0.3s ease;
    transform-style: preserve-3d;
    transform: perspective(800px) rotateY(35deg) scale(0.6) rotateX(25deg);
    filter: blur(2px);
    opacity: 0;
    pointer-events: none !important;
    width: 170px;
    @include bp(tph) {
        padding: 0 12px;
        font-size: 16px;
        width: 160px;
        letter-spacing: 0.1em;
        border-radius: 40px;
        height: 42px;
        font-weight: 500;
        line-height: 24px;
    }
    &.visible {
        pointer-events: auto !important;
        transform: perspective(800px) rotateY(0deg) translateY(0px) rotateX(0deg) scale(1);
        filter: blur(0);
        opacity: 1;
    }
    &.hidden {
        transition: 1s ease transform, 1s ease filter, 0.8s opacity ease, background-color 0.3s ease;
        transform: perspective(800px) rotateY(25deg) scale(0.6) rotateX(-25deg);
        filter: blur(2px);
        opacity: 0;
        pointer-events: none !important;
    }
    @media (hover: hover) {
        &:hover {
            background: rgba(245, 232, 219, 0.7);
        }
    }
}

.RoundedRectButtonLarge {
    background: rgba(#FEF7EF, 0.7);
    border: 1px solid #D0BAA3;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    width: 170px;
    height: 40px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    font-style: normal;
    text-transform: uppercase;
    color: #000000;
    flex-flow: row;
    position: fixed;
    justify-content: space-evenly;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.1em;
    @include bp(tph) {
        width: 210px;
        height: 42px;
        border-radius: 21px;
        padding: 0 12px;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1em;
    }
    @include bp(tl) {
        width: 250px;
        height: 70px;
        border-radius: 40px;
        padding: 0 12px;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1em;
    }
    svg {
        width: 16px;
        height: 20px;
        @include bp(tl) {
            width: 27px;
            height: 33px;
        }
        path:nth-child(2) {
            fill: #C9B39D;
            transition: all 0.4s ease;
        }
    }

    transition: 1.2s ease transform, 1.2s ease filter, 1s opacity ease, background-color 0.3s ease;
    transform-style: flat;
    transform: perspective(800px) rotateY(35deg) scale(0.6) rotateX(25deg);
    filter: blur(2px);
    opacity: 0;
    pointer-events: none !important;

    &.visible {
        pointer-events: auto !important;
        transform: perspective(800px) rotateY(0deg) translateY(0px) rotateX(0deg) scale(1);
        filter: blur(0);
        opacity: 1;
    }

    &.hidden {
        transition: 1s ease transform, 1s ease filter, 0.8s opacity ease, background-color 0.3s ease;
        transform: perspective(800px) rotateY(25deg) scale(0.6) rotateX(-25deg);
        filter: blur(2px);
        opacity: 0;
        pointer-events: none !important;
    }
    @media (hover: hover) {
        &:hover {
            background: rgba(245, 232, 219, 0.7);

            svg {
                path:nth-child(2) {
                    fill: transparent;
                }
            }
        }
    }
}

.TryOwnRecordingButton {
    bottom: 16px;
    left: calc(50% - 170px * 0.5);
    @include bp(tp) {
        left: calc(50% - 125px + #{$MENU_WIDTH} * 0.5);
        bottom: 30px;
    }
    @include bp(tl) {
        bottom: 60px;

    }
}
.ViewYourSongMatch {
    bottom: 16px;
    left: calc(50% - 150px);
    width: 190px;

    @media (min-width: 375px) {
        left: calc(50% - 160px);
    }
    @include bp(tp) {
        left: calc(50% - 105px);
        bottom: 30px;
        width: 280px;
    }
    @include bp(tph) {
        bottom: 30px;
    }
    @include bp(tl) {
        left: calc(50% - 125px + #{$MENU_WIDTH} * 0.5);
        bottom: 60px;

    }
}
.step1-cta {
    position: fixed;
    bottom: 16px;
    width: 220px;
    @include bp(tph) {
        bottom: 30px;
    }
    @include bp(tl) {
        bottom: 60px;

    }
}