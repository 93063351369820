#my-songs {
    width: 100%;
    flex-flow: column;
    align-items: center;

    .page-content {
        max-width: 1200px;
    }

    .mySongs-info {
        margin-top: 20px;
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        box-sizing: border-box;
        padding: 10px;
        @include bp(tp) {
            padding: 20px;
        }

        h5 {
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 21px;
        }

        h3 {
            font-style: normal;
            font-weight: 500;
            font-size: 19px;
            line-height: 21px;
            @include bp(tphLow) {
                font-size: 16px;
                line-height: 19px;
            }
        }


        span {
            font-style: normal;
            font-weight: 600;
            font-size: 10px;
            line-height: 21px;
            color: #D0BAA3;
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        margin-top: 32px;
        padding-top: 52px;
        border-top: 1px solid #C9B39D;
        @include bp(tp) {
            border-top: none;
            padding-top: 0;
            //margin-top: 10%;

        }

        li {
            list-style: none;
            margin: 0;
            padding: 0;
            width: 100%;
            margin-top: 10%;
            @include bp(tp) {
                width: 80%;
            }

            display: flex;
            flex-flow: column;
            align-items: center;
            cursor: pointer;

            &.no-preview {
                cursor: default;

                .PlayButton {
                    display: none;
                }
            }

            .SongUI-albumArt {
                width: 40%;

            }
        }

        &.list-length-1 {
            .mySongs-info {
                h5 {
                    font-size: 16px;
                    line-height: 24px;
                }

                h3 {
                    font-size: 22px;
                    line-height: 24px;
                }

                span {
                    font-size: 13px;
                    line-height: 24px;
                }
            }
        }


        @include bp(tp) {
            &.list-length-2 {
                li {
                    width: 50%;
                }
            }
            &.list-length-3 {
                li {
                    width: 33%;
                }
            }
        }
    }
}