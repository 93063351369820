
.PagesWrapper {
    display: none;
    width: 100%;
    padding-top: $MENU_HEIGHT_MOBILE + 35px;
    padding-bottom: 35px;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    @include bp(tp) {
        width: calc(100% - #{$MENU_WIDTH});
        margin-left: $MENU_WIDTH;
        padding-top: 66px;
        padding-bottom: 60px;
    }
    @include bp(tphLow) {
        padding-top: 36px;
        padding-bottom: 30px;
    }
}

html.is-safari {
    .PagesWrapper {
        z-index: 1;
    }
}

html.show-pages {
    .PagesWrapper {
        display: flex;
    }

    .CanvasContent {
        display: none;
    }
}

.page {
    display: none;
    padding: 0 40px;

    &.page-visible {
        display: flex;
    }

    .page-content {
        width: 100%;
        flex-flow: column;
        align-items: center;
        max-width: 1040px;
    }
}

.TransitionCoverLayer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $COLOR_BG;
    will-change: opacity;
    opacity: 0;
    z-index: 2;
    pointer-events: none;
}

html.page-about {
    .CanvasRenderer {
        display: none;
    }
}